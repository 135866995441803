<template>
 <div id="staff-assign">

   <!-- BREADCRUMB -->
   <breadcrumb-base :breadcrumb="breadcrumb" :title="'AssignStaff'">
     <template v-slot:right-end>
       <div class="vx-row">
       </div>
     </template>
   </breadcrumb-base>
   <div class="vs-row">
     <div class="vs-col md:w-2/5">
       <div class="text-center">
         <h2 class="custom-table-header">{{$t('UnAssignedStaff')}}</h2>
       </div>
       <vs-table ref="table" :data="unAssignedStaff">

         <template slot="header">

         </template>
         <template slot="thead">
           <vs-th>
             <vs-checkbox @input="onAllUnAssignedCheck" v-model="unAssignedCheckedAll" ></vs-checkbox>
           </vs-th>
           <vs-th >{{ $t('ID') }}</vs-th>
           <vs-th >{{ $t('Name') }}</vs-th>
         </template>

         <template slot-scope="{data}">
           <tbody>
           <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

             <vs-td>
               <vs-checkbox
                 @input="onUnAssignedSelected"
                 v-model="unAssignedSelection[tr.id.toString()]"
                 :vs-value="tr.id.toString()"></vs-checkbox>
             </vs-td>
             <vs-td>
               <p class="font-medium truncate">{{ tr.id }}</p>
             </vs-td>
             <vs-td>
               <p class="font-medium truncate">{{ tr.username }}</p>
             </vs-td>
           </vs-tr>
           </tbody>
         </template>

       </vs-table>
     </div>
     <div class="vs-col md:w-1/5 flex justify-center mt-12">
       <vs-button color="success" @click="updateStaffAssign">
         {{ $t('Update') }} {{unAssignedSelectionLength + assignedSelectionLength}}
         {{ $t('Changes') }}</vs-button>
     </div>
     <div class="vs-col md:w-2/5">
       <div class="text-center">
         <h2 class="custom-table-header">{{$t('AssignedStaff')}}</h2>
       </div>
       <vs-table ref="table" :data="assignedStaff">

         <template slot="header">
         </template>
         <template slot="thead">
           <vs-th>
             <vs-checkbox @input="onAllAssignedCheck" v-model="assignedCheckedAll" ></vs-checkbox>
           </vs-th>
           <vs-th >{{ $t('ID') }}</vs-th>
           <vs-th >{{ $t('Name') }}</vs-th>
         </template>

         <template slot-scope="{data}">
           <tbody>
           <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

             <vs-td>
               <vs-checkbox
                 @input="onAssignedSelected"
                 v-model="assignedSelection[tr.id.toString()]"
                 :vs-value="tr.id.toString()"></vs-checkbox>
             </vs-td>
             <vs-td>
               <p class="font-medium truncate">{{ tr.id }}</p>
             </vs-td>
             <vs-td>
               <p class="font-medium truncate">{{ tr.username }}</p>
             </vs-td>
           </vs-tr>
           </tbody>
         </template>

       </vs-table>
     </div>
   </div>
 </div>
</template>

<script>
export default {
  name: 'StaffAssign',
  data() {
    return {
      assignedStaff: [],
      unAssignedStaff: [],
      unAssignedSelection: [],
      assignedSelection: [],
      unAssignedCheckedAll: false,
      assignedCheckedAll: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Users', i18n: 'Users', url: '/users/view' },
        { title: 'Assign Staff', i18n: 'AssignStaff', active: true },
      ],
    }
  },
  props: {
    userId: {
      type: Number || String,
      default: null,
    },
  },
  computed: {
    // unAssignedCheckedAll() {
    //   if(this.unAssignedStaff.length > 0) {
    //     return this.unAssignedSelection.length === this.unAssignedStaff.length
    //   }
    //   return false
    // }

    unAssignedSelectionLength() {
      return Object.values(this.unAssignedSelection).filter((v) => v != null).length
    },
    assignedSelectionLength() {
      return Object.values(this.assignedSelection).filter((v) => v != null).length
    },
  },
  methods: {
    onAllUnAssignedCheck() {
      this.unAssignedSelection = []
      this.unAssignedStaff.forEach((staff) => {
        this.unAssignedSelection[staff.id] = staff.id.toString()
      })
    },
    onAllAssignedCheck() {
      this.assignedSelection = []
      this.assignedStaff.forEach((staff) => {
        this.assignedSelection[staff.id] = staff.id.toString()
      })
    },
    onUnAssignedSelected() {
      this.unAssignedCheckedAll = this.unAssignedSelectionLength === this.unAssignedStaff.length
    },
    onAssignedSelected() {
      this.assignedCheckedAll = this.assignedSelectionLength === this.assignedStaff.length
    },
    updateStaffAssign() {
      const total = this.unAssignedSelectionLength + this.assignedSelectionLength
      if (total <= 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select atleast one',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      }

      const data = []
      Object.values(this.unAssignedSelection).filter((v) => v != null).forEach((d) => {
        data.push({
          mode: 0,
          user_id: d,
        })
      })
      Object.values(this.assignedSelection).filter((v) => v != null).forEach((d) => {
        data.push({
          mode: 1,
          user_id: d,
        })
      })
      const payload = {
        manager_id: this.userId,
        staff_assignments: JSON.stringify(data),
      }
      this.$store.dispatch('user/fetchStaffAssign', payload).then((res) => {
        this.assignedStaff = res.data.assigned_staff
        this.unAssignedStaff = res.data.un_assigned_staff
        this.assignedSelection = []
        this.unAssignedSelection = []
        this.assignedCheckedAll = false
        this.unAssignedCheckedAll = false
      }).catch((error) => {
        this.$vs.loading.close()
        this.errorFetching = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    fetchStaffAssign() {
      const payload = {
        'manager_id': this.userId,
      }
      this.$store.dispatch('user/fetchStaffAssign', payload).then((data) => {
        this.assignedStaff = data.data.assigned_staff
        this.unAssignedStaff = data.data.un_assigned_staff
      }).catch((error) => {
        this.$vs.loading.close()
        this.errorFetching = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
  },
  mounted() {
    this.fetchStaffAssign()
  },
}
</script>

<style lang="scss">
#staff-assign {

  .custom-table-header {
    font-size: 20px;
    font-weight: 600;
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .customer-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .supplier-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 .2rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 8px 2px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
